@import "~bootstrap/dist/css/bootstrap.css";
@import "~primereact/resources/themes/lara-light-indigo/theme.css";
@import "~primereact/resources/primereact.min.css";
@import "~primeicons/primeicons.css";

.form-group {
  margin-bottom: 1rem;
}
.form-control:focus {
  box-shadow: none;
  outline: none;
}
body {
  background-color: #dfe7ff !important;
}
@media (max-width: 768px) {
  body {
    background-color: #5c50db !important;
  }
}
.btn-primary {
  background-color: #556ee6 !important;
  border-color: #556ee6 !important;
}
.text-primary {
  color: #556ee6 !important;
}
.card {
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%) !important;
  border-color: #f6f6f6 !important;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
}
.p-datatable .p-column-header-content {
  color: #444 !important;
  white-space: nowrap;
  font-size: 0.7em;
  text-transform: uppercase;
}
.p-column-filter-clear-button {
  display: none;
}
.p-datatable .p-datatable-tbody > tr > td {
  font-size: 0.8em !important;
  font-weight: 400;
}
.p-datatable .p-paginator-bottom {
  border: none !important;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: 0;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  width: 30px;
  height: 30px;
  font-size: 12px;
}
.p-paginator .p-paginator-current {
  font-size: 15px;
}

.container-box {
  border-radius: 0.5em;
  box-shadow: 0 0.25rem 0.5rem rgb(18 38 63 / 50%);
  border: 1px solid #edf2f9;
  background-color: #fff;
}

/**Loading Indicator CSS starts**/
.loading-indicator ul {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  display: flex;
}
.loading-indicator ul li {
  list-style: none;
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  animation: grow 1.6s ease-in-out infinite;
}
@keyframes grow {
  0%,
  40%,
  100% {
    transform: scale(0.2);
  }
  20% {
    transform: scale(1);
  }
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.loading-indicator ul li {
  background: #7c7c7c;
  box-shadow: 0 0 50px #7c7c7c;
}
.loading-indicator ul li:nth-child(1) {
  animation-delay: -1.4s;
}
.loading-indicator ul li:nth-child(2) {
  animation-delay: -1.2s;
}
.loading-indicator ul li:nth-child(3) {
  animation-delay: -1s;
}
.loading-indicator ul li:nth-child(4) {
  animation-delay: -0.8s;
}
.loading-indicator ul li:nth-child(5) {
  animation-delay: -0.6s;
}
.loading-indicator ul li:nth-child(6) {
  animation-delay: -0.4s;
}
.loading-indicator ul li:nth-child(7) {
  animation-delay: -0.2s;
}

@keyframes scaleHighlight {
  0% {
    transform: scale(1);
    opacity: 1;
    color: green;
  }
  50% {
    transform: scale(0.8);
    opacity: 0.8;
    color: #00b900;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    color: green;
  }
}

.highlighted-icon {
  color: #0009b9 !important;
  font-weight: bold;
  stroke-width: 3;
}

/**Loading Indicator CSS Ends**/
